import { css, html, LitElement, nothing } from "lit"
import { atBreakpoint, brandonGrotesque } from "#js/components/styles"
import { getUrlWithTracking } from "#js/components/utm"
import { debounce } from "#js/components/utils"
import { styleMap } from "lit/directives/style-map.js"
import { unsafeHTML } from "lit/directives/unsafe-html.js"

class Share extends LitElement {
  static properties = {
    title: { type: String },
    text: { type: String },
    url: { type: URL },
  }

  static styles = css`
    :host {
      margin-inline: 0 0;
    }

    svg {
      width: 1.3em;
      height: 1.3em;
      cursor: pointer;
      vertical-align: middle;
    }
  `

  constructor() {
    super()
    this.url = new URL(globalThis.location.href)
    this.title = document.title
    this.text = document.querySelector('meta[property="description"]')?.getAttribute(
      "content",
    )
  }

  render() {
    const shareData = this.getShareData()
    if (navigator.canShare !== undefined && navigator.canShare(shareData)) {
      return html`
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
             viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
             stroke-linecap="round" stroke-linejoin="round"
             @click="${this.onClick.bind(this)}">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <path d="M6 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"/>
          <path d="M18 6m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"/>
          <path d="M18 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"/>
          <path d="M8.7 10.7l6.6 -3.4"/>
          <path d="M8.7 13.3l6.6 3.4"/>
        </svg>
      `
    } else {
      console.debug("Web Share API not supported")
      return nothing
    }
  }

  getShareData() {
    return {
      title: this.title,
      text: this.text,
      url: getUrlWithTracking(this.url.toString(), {
        utm_source: "share",
        utm_medium: "social",
      }),
    }
  }

  async onClick(event) {
    const shareData = this.getShareData()
    console.debug(shareData)
    try {
      await navigator.share(shareData)
    } catch (error) {
      // Ignore AbortError - caused by the user cancelling the share dialog
      if (error.name !== "AbortError") {
        throw error
      }
    }
  }
}

globalThis.customElements.define("ui-share", Share)

/**
 * A wrapper for the html [`<picture>`](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/picture) element. Designed to work with the default
 * serialized image from [django-pictures](https://pypi.org/project/django-pictures/). To be used withing a lit component.
 * @param {object} picture - The picture object.
 * @param {string} picture.url - The url of the image.
 * @param {number} picture.width - The width of the image.
 * @param {number} picture.height - The height of the image.
 * @param {object} picture.ratios - The picture ratios object.
 * @param {string} alt - The alt text for the image.
 * @param {string} ratio - The aspect ratio of the image.
 * @param {object} pictureAttrs - The attributes for the picture element.
 * @param {object} imgAttrs - The attributes for the img element.
 * @returns {string} - The picture element as a string.
 */
export function picture(
  { url, width, height, ratios },
  alt,
  ratio,
  pictureAttrs = {},
  imgAttrs = {},
) {
  return `
    <picture ${attrsToString(pictureAttrs)}>
      ${
    Object.entries(ratios[ratio].sources).map(([type, srcset]) =>
      `<source type="${type}"
                    srcset="${
        Object.entries(srcset).map(([px, url]) => `${url} ${px}w`).join(", ")
      }" sizes="${ratios[ratio].media}">`
    ).join("")
  }
      <img src="${url}"
           width="${width}"
           height="${height}"
           alt="${alt}"
           ${attrsToString(imgAttrs)}/>
    </picture>
  `
}

/**
 * Convert object to string of HTML attributes.
 * @param {object} attrs - Key-value pairs of attributes.
 * @returns {string} - String of HTML attributes.
 */
export function attrsToString(attrs) {
  return Object.entries(attrs).map(([k, v]) => `${k}="${v}"`).join(" ")
}

/**
 * Wrapper to scroll slotted overflow content horizontally.
 *
 * Adds left and right arrows to scroll the content.
 * Updates the visibility of the arrows based on the scroll position.
 * Scrolls to the active element on mount.
 */
class HorizontalScroll extends LitElement {
  static properties = {
    _scrollDistance: { type: Number, attribute: false },
  }

  static styles = css`
    :host {
      position: relative;
      padding: 0 2.5em;
    }

    .arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      font-size: 1.25em;
      height: 1.5em;
      width: 1.5em;
      cursor: pointer;
      color: var(--text-color);
      transition: opacity 250ms;
      background-color: var(--white);
      border-radius: 100vh;
      border: thin solid var(--white-smoke);

      &::before {
        display: inline-block;
        content: "";
        height: 0.49em;
        aspect-ratio: 1;
        margin: 0.5em;
        box-sizing: border-box;
        transform: rotate(45deg) translate(-0.05em, 0.05em);
        transition: transform 150ms ease-in-out;
        border-top-width: 0.1em;
        border-top-style: solid;
        border-inline-end-width: 0.1em;
        border-inline-end-style: solid;
        vertical-align: text-top;
        border-color: var(--text-color);
      }
    }

    .arrow--left {
      &::before {
        transform: translate(0.1em, 0) scaleX(-1) rotate(45deg);
      }
    }
  `

  firstUpdated() {
    const slot = this.shadowRoot.querySelector("slot")
    this._container = slot.assignedElements({ flatten: true })[0]
    this._container.addEventListener(
      "scroll",
      debounce(() => this.requestUpdate(), 150).bind(this),
    )
    this._scrollDistance = this._container.clientWidth / 2

    // set the scroll position to the active element
    setTimeout(() => {
      const activeElement = this._container.querySelector(`[aria-current="true"]`)
      if (activeElement) {
        this._container.scrollLeft = activeElement.offsetLeft - this._scrollDistance
        this.requestUpdate()
      }
    }, 150)
  }

  render() {
    return html`
      <div class="arrow arrow--left"
           @click="${() => this.scroll(this._scrollDistance * -1)}"
           style="${styleMap(this.getLeftArrowStyles())}">
      </div>
      <slot></slot>
      <div class="arrow arrow--right"
           @click="${() => this.scroll(this._scrollDistance)}"
           style="${styleMap(this.getRightArrowStyles())}">
      </div>
    `
  }

  getLeftArrowStyles() {
    return {
      opacity: this._container && this._container.scrollLeft === 0 ? 0.3 : 1,
      left: 0,
    }
  }

  getRightArrowStyles() {
    return {
      opacity: (
        this._container &&
          this._container.scrollWidth -
                this._container.scrollLeft -
                this._container.clientWidth <= 0
          ? 0.3
          : 1
      ),
      right: 0,
    }
  }

  scroll(amount) {
    this._container.scrollBy({ left: amount, behavior: "smooth" })
  }
}

globalThis.customElements.define("ui-horizontal-scroll", HorizontalScroll)

class Icon extends LitElement {
  static properties = {
    name: { type: String },
    strokeWidth: { type: Number },
  }

  static styles = css`
    :host {
      display: inline-block;
      color: inherit;
      line-height: 1;
      vertical-align: sub;
    }

    svg {
      width: 1.3em;
      height: 1.3em;
    }
  `

  constructor() {
    super()
    this.strokeWidth = 1.5
  }

  render() {
    return html`
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        stroke-width="${this.strokeWidth}"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round">
        <use href="${globalThis.tablerIconSprite}#tabler-${this.name}"></use>
      </svg>
    `
  }
}

globalThis.customElements.define("tabler-icon", Icon)

/**
 * An Undraw illustration vector image component.
 *
 * Use the `name` attribute to specify the illustration to display.
 * The name should be the title of the illustration, e.g. "Social media".
 * You can find a full list here: https://undraw.co/illustrations
 * @element undraw-illustration
 */
class UndrawIllustration extends LitElement {
  static properties = {
    name: {
      type: String,
      converter: {
        fromAttribute: (value, type) => value.replace(/\s+/g, "-").toLowerCase(),
      },
    },
  }

  static styles = css`
    :host {
      display: inline-block;
      color: inherit;
      line-height: 1;
      vertical-align: sub;
    }

    :host([position="right"]) {
      float: inline-end;
    }

    :host([position="left"]) {
      float: inline-start;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  `

  render() {
    return html`
      <svg
        fill="none"
        viewBox="0 0 300 300"
        xmlns="http://www.w3.org/2000/svg">
        <use href="${globalThis.undrawIllustrationSprite}#${this.name}"></use>
      </svg>
    `
  }
}

globalThis.customElements.define("undraw-illustration", UndrawIllustration)

class OfferPreview extends LitElement {
  static get properties() {
    return {
      offer: { type: Object, attribute: false },
    }
  }

  static get styles() {
    return css`
      .offer-preview {
        --admonition-color: var(--interactive-color);

        display: flex;
        gap: 1em;
        flex-flow: row nowrap;
        padding: 1rem;
        margin: 1rem 0;
        border-left: thick solid var(--admonition-color);
        background-color: light-dark(
          hsl(from var(--admonition-color) h 50% 98%),
          hsl(from var(--admonition-color) h 50% 2%)
        );
        text-decoration: none;
        color: var(--text-color);
        min-height: 10rem;
        width: 100%;

        ${atBreakpoint("mobile", css`flex-flow: column nowrap;`)}

        picture {
          height: 10rem;

          img {
            height: 10rem;
            width: auto;
          }
        }

        .title {
          ${brandonGrotesque}
          font-weight: 500;
          margin-block-end: 0.5rem;
          color: light-dark(
            hsl(from var(--admonition-color) h 100% 25%),
            hsl(from var(--admonition-color) h 100% 75%)
          );
        }

        p:last-child {
          margin-block-end: 0;
        }
      }
    `
  }

  constructor() {
    super()
    this.offer = {}
  }

  async firstUpdated() {
    this.offer = await this.fetchOffer()
  }

  render() {
    const aspectRatio = this.getAttribute("id").split("-")[0] === "expert"
      ? "1/1"
      : "3/2"

    return html`
      <a class="offer-preview" href="${this.offer.public_url}">
        ${
      this.offer.picture
        ? unsafeHTML(picture(this.offer.picture, this.offer.title, aspectRatio))
        : ""
    }
        <div class="content">
          <div class="title">${this.offer.title}</div>
          <p>${this.offer.subtitle}</p>
        </div>
      </a>
    `
  }

  async fetchOffer() {
    const [offerType, offerID] = this.getAttribute("id").split("-")
    const response = await fetch(
      new URL(
        `/api/preview/${offerType}/${offerID}`,
        globalThis.location.origin,
      ),
    )

    if (!response.ok) {
      console.error(`Failed to fetch offer: ${response.statusText}`)
      return {
        picture: false,
        title: response.status,
        subtitle: response.statusText,
      }
    }
    return response.json()
  }
}

customElements.define("offer-preview", OfferPreview)
